<template>
 <div class="max-w-7xl mx-auto py-4">
  <div
   class="col-span-4 flex mx-4 pb-3"
   v-if="$route.name === 'DashboardExpertStatistics'">
   <div
    :class="[showSideNav ? 'beta__logo' : 'left-40', 'fixed z-1  mt-1 text-xs']">
    <span
     class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-4 h-4 mr-1">
      <path
       d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
     </svg>
     {{ $t("beta") }}
    </span>
   </div>
   <PbxDashboardSelector
    :key="urlType"
    @action="setPeriod(selectedPeriod)"
    @all-selected="$emit('all-selected', $event)"
    :urlType="urlType"
    @elementToRemove="elementToRemove = $event"
    :queryElement="queryElement"
    :elements="this.pbxElements ? this.pbxElements : []" />
  </div>
  <div
   class="lg:grid lg:grid-cols-4 lg:gap-4 sm:space-y-0 lg:space-y-0 md:mx-4">
   <div class="col-span-4 flex justify-between">
    <div class="text-xs font-medium text-gray-700">
     <div
      :class="[
       $route.name === 'DashboardExpertStatistics' ? '' : 'left-32',
       'fixed z-1 ',
      ]">
      <span
       class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-4 h-4 mr-1">
        <path
         d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
       </svg>
       {{ $t("beta") }}
      </span>
     </div>
     <div
      class="fixed z-10"
      @mouseover="showDatePickers = true"
      @mouseleave="showDatePickers = false">
      <div class="flex items-center gap-4">
       <div
        class="flex items-center bg-white shadow-md rounded-md border-gray-400 z-10">
        <p class="border-r px-2 py-2">
         {{
          toLocaleDateString(startDate) +
          (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
         }}
        </p>
        <div
         @click="showDatePickers = true"
         class="px-2 py-2 cursor-pointer bg-gray-400 border-gray-400 text-white hover:bg-blue-400 rounded-r-md">
         <font-awesome-icon icon="calendar" class="flex-shrink-0" />
        </div>
       </div>
      </div>
      <transition name="fade">
       <div
        v-show="showDatePickers"
        class="space-y-1 transition ease-in-out delay-150 mt-1 px-1 text-left">
        <div v-for="option in calendarOptions" :key="option.value">
         <div v-if="option.value === 'custom'">
          <v-date-picker
           class="inline-block h-full w-full"
           color="teal"
           v-model="customDate"
           :max-date="new Date()"
           mode="date"
           is-range>
           <template v-slot="{ togglePopover }">
            <button
             @click="togglePopover()"
             :class="{
              'bg-blue-500 text-white': selectedPeriod === option.value,
              'hover:bg-blue-600 hover:text-white':
               selectedPeriod !== option.value,
             }"
             class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
             <font-awesome-icon
              :icon="option.icon"
              class="flex-shrink-0 mr-1.5" />
             {{ $t("dashboards.custom") }}
            </button>
           </template>
          </v-date-picker>
         </div>
         <button
          v-else
          @click="selectPeriod(option.value)"
          :class="{
           'bg-blue-500 text-white': selectedPeriod === option.value,
           'hover:bg-blue-600 hover:text-white':
            selectedPeriod !== option.value,
          }"
          class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
          <font-awesome-icon :icon="option.icon" class="flex-shrink-0 mr-1.5" />
          {{ $t(option.name) }}
         </button>
        </div>
       </div>
      </transition>
     </div>
    </div>
    <div class="z-1 mt- flex lg:mt-0 gap-2">
     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Envoyer un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareReport.show({
         title: $t('pbxReport.shareTitle'),
         message: $t('pbxReport.shareMessage'),
         isSchedule: false,
        })
       ">
       <font-awesome-icon
        icon="share"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>

     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Planifier un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareScheduleReport.show({
         title:
          $t('Planifier un rapport') +
          ' ' +
          $t('daily') +
          ', ' +
          $t('weekly') +
          ' ' +
          $t('or') +
          ' ' +
          $t('monthly'),
         message: $t('Le rapport a été planifié avec succès'),
         isSchedule: true,
        })
       ">
       <font-awesome-icon
        :icon="['far', 'calendar-check']"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>
     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Créer une alerte')"
       @click="
        this.$refs.popupCreateAlert.show({
         title: $t('Créer une alerte'),
         message: $t(''),
         isSchedule: false,
        })
       "
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color">
       <font-awesome-icon
        :icon="['far', 'bell']"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>
    </div>
    <!-- <div class="text-xs font-medium text-gray-700 mr-52">
     <div class="fixed z-10 flex gap-4">
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       Total des appels
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
     </div>
    </div> -->
   </div>
   <!-- stats total calls cards -->
   <div
    v-if="
     (totalCallsInboundPbx ||
      totalCallsInternalPbx ||
      totalCallsOutboundPbx ||
      talkingInboundPbx ||
      talkingInternalPbx ||
      talkingOutboundPbx) &&
     !urlType
    "
    class="col-span-4 space-y-2 pt-2 pb-8 bg-white shadow-md px-2 rounded-sm"
    id="stats-total">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">total des appels</h2>
    </div>
    <div
     class="flex justify-center gap-60"
     v-if="totalCallsArrPbx && totalCallsArrDurationPbx"
     :key="totalCallsArrPbx && totalCallsArrDurationPbx">
     <TotalCallsCard
      :key="totalCallsArrPbx"
      title="Nombre"
      :duration="false"
      :inboundCalls="totalCallsInboundPbx"
      :outboundCalls="totalCallsOutboundPbx"
      :internalCalls="totalCallsInternalPbx"
      :totalCallsArr="totalCallsArrPbx" />
     <TotalCallsCard
      :key="totalCallsArrDurationPbx"
      title="Durée"
      :duration="true"
      :inboundCalls="talkingInboundPbx"
      :outboundCalls="talkingOutboundPbx"
      :internalCalls="talkingInternalPbx"
      :totalCallsArr="totalCallsArrDurationPbx" />
    </div>

    <div class="pt-4">
     <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700">
       * Appels émis vers l'extérieur et appels reçus depuis l'extérieur
      </span>
     </div>
     <!-- <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700"> ** File uniquement </span>
     </div> -->
    </div>
   </div>
   <!-- stats incomming calls cards -->
   <div
    v-if="dataQueueTable?.length && urlType !== 'extension'"
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-incomming">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mb-6">
     <h2 class="uppercase text-lg font-bold mb-">
      APPELS reçus SUR MES FILES D'ATTENTES
     </h2>
    </div>
    <div class="grid grid-cols-3 space-x-4">
     <lost-calls-card
      v-if="totalCalls"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10" />
     <avg-answer-time-card
      v-if="totalCalls && waiting"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="parseInt(waiting / totalCalls)"
      :talking_10="talking_10" />
     <ten-seconds-calls-card
      v-if="totalCalls && talking_10"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10" />
    </div>
    <!-- stats duration total / duration avg / longest call  -->
    <div
     v-if="dataColumnChart?.length && dataColumnChart[0]?.data?.length"
     class="col-span-4 bg-white rounded-lg py-2">
     <div class="flex justify-between"></div>
    </div>
    <div class="grid grid-cols-subgrid col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChart.length &&
       dataColumnChart[0] &&
       dataColumnChart[0].data &&
       dataColumnChart[0].labels &&
       dataColumnChartHour.length &&
       dataColumnChartHour[0] &&
       dataColumnChartHour[0].data &&
       dataColumnChartHour[0].labels
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHour[0]['data']
        : dataColumnChart[0]['data']
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHour[0]['labels']
        : dataColumnChart[0]['labels']
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChart[0]['data'])"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChart[0]['data'])"
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="grid grid-cols-4 gap-2 text-sm">
     <div
      v-if="talking && totalCalls"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels reçus est de
         <span class="">
          {{
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[0])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[1])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ lowestRateHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ lowestRateHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ lowestRateHour.split(":")[1] }}
          des appels ne sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ bestRateHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class=""> {{ bestRateHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ bestRateHour.split(":")[1] }}
          des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ mostCallsHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class=""> {{ mostCallsHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ mostCallsHour.split(":")[1] }}
          ont été reçus .
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre 9h et 18h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats outgoing calls cards -->
   <div
    v-if="
     dataColumnChartOutbound?.length &&
     dataColumnChartOutbound[0]?.data?.length &&
     dataColumnChartHourOutbound.length &&
     dataColumnChartHourOutbound[0]?.data?.length &&
     !urlType
    "
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-outgoing">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">appels émis externe</h2>
    </div>
    <div class="col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChartOutbound.length &&
       dataColumnChartOutbound[0] &&
       dataColumnChartOutbound[0].data &&
       dataColumnChartOutbound[0].labels &&
       dataColumnChartHourOutbound.length &&
       dataColumnChartHourOutbound[0] &&
       dataColumnChartHourOutbound[0].data &&
       dataColumnChartHourOutbound[0].labels
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourOutbound[0]['data']
        : dataColumnChartOutbound[0]['data']
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourOutbound[0]['labels']
        : dataColumnChartOutbound[0]['labels']
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChartOutbound[0]['data'])"
      :totalUnanswered="
       this.getUnansweredNumb(dataColumnChartOutbound[0]['data'])
      "
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="grid grid-cols-4 gap-2 text-sm">
     <div
      v-if="talkingOutbound && totalCallsOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels émis est de
         <span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ lowestRateHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ lowestRateHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ lowestRateHourOutbound.split(":")[1] }}
          des appels ne sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ bestRateHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ bestRateHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ bestRateHourOutbound.split(":")[1] }}
          des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ mostCallsHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ mostCallsHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ mostCallsHourOutbound.split(":")[1] }}
          ont été émis.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre 9h et 18h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats top 10 users -->
   <div
    v-if="dataColumnChartTopUsers?.length && urlType !== 'queue'"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm"
    id="stats-top">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 class="uppercase text-lg font-bold">Top 10 utilistateurs</h2>
    </div>
    <!-- <div
     class="px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 -mb-4">
     Voici un aperçu des dix
     <span class="font-medium">principaux utilisateurs</span> en fonction du
     nombre d'appels entrants et sortants auxquels ils ont répondu. Les données
     révèlent une répartition diversifiée de l'activité téléphonique parmi ces
     utilisateurs, avec certains occupant des positions plus élevées en raison
     de leur <span class="font-medium">engagement accru dans les appels</span>.
     Ce classement offre un aperçu précieux de l'utilisation des communications
     au sein de notre réseau, mettant en lumière les membres les plus actifs et
     les tendances d'utilisation dominantes.
    </div> -->

    <TopUsersCard
     :key="dataColumnChartTopUsers"
     v-if="
      dataColumnChartTopUsers.length && dataColumnChartTopUsersLabels.length
     "
     @quickPeriod="quickPeriodAnswered = $event"
     :quickPeriod="quickPeriodAnswered"
     @selectPeriod="periodSelectorAnswered = $event"
     :seriesForColumn="dataColumnChartTopUsers"
     :categoriesForColumn="dataColumnChartTopUsersLabels" />
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (1) Appels externes reçus ayant été décrochés par l'utilisateur. Les
      appels non décrochés ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (2) Appels externes émis par l'utilisateur et répondus par l'appelé. Les
      appels non répondus ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (3) Appels internes émis ou reçus par l'utilisateur ayant été répondus.
      Les appels non répondus ne sont pas pris en compte.
     </span>
    </div>
   </div>
   <div
    v-if="urlType ? !pbxElements || pbxElements?.length == 0 : false"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 v-if="urlType === 'queue'" class="uppercase text-lg font-bold">
      Tableau de Bord Files D'attente
     </h2>
     <h2 v-if="urlType === 'extension'" class="uppercase text-lg font-bold">
      Tableau de bord Utiliateurs
     </h2>
    </div>
    <div class="pt-8 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div
      v-if="!pbxElements || pbxElements.length <= 0"
      class="text-center max-w-lg mx-auto space-y-4">
      <font-awesome-icon
       icon="chart-bar"
       class="mx-auto h-10 w-10 text-teal-600"
       aria-hidden="true" />
      <h1 class="text-gray-900 font-bold text-2xl">DASHBOARD</h1>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       <span v-if="urlType === 'queue'">
        Les indicateurs essentiels pour analyser les appels reçus sur vos files
        d’attente.
       </span>
       <span v-if="urlType === 'extension'">
        Les indicateurs essentiels pour analyser les appels par utilisateurs.
       </span>
      </h3>
      <div class="pt-10">
       <PleaseSelectElement
        v-if="urlType === 'queue'"
        dataDescription="Veuillez sélectionner une file et une période" />
       <PleaseSelectElement
        v-if="urlType === 'extension'"
        dataDescription="Veuillez sélectionner un utilisateur et une période" />
      </div>
     </div>
     <div
      v-else
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
      <div class="overflow-hidden border-b border-gray-200 rounded-md">
       <NoData />
      </div>
     </div>
    </div>
   </div>

   <!-- <div class="col-span-1" v-if="dataQueueTable.length">
    <TopQueuesCard :data="dataQueueTable" />
   </div> -->
  </div>
  <TransitionRoot as="template" :show="reportList">
   <Dialog as="div" class="relative z-40" @close="reportList = false">
    <div class="fixed inset-0" />

    <div class="fixed inset-0 overflow-hidden">
     <div class="absolute inset-0 overflow-hidden">
      <div
       class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
       <TransitionChild
        as="template"
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enter-from="translate-x-full"
        enter-to="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leave-from="translate-x-0"
        leave-to="translate-x-full">
        <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
         <div
          class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
          <div class="px-4 sm:px-6">
           <div class="flex items-start justify-between">
            <DialogTitle class="w-full text-lg font-medium text-gray-900">
             <div
              class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
              <h2 class="uppercase text-lg font-bold">
               Rapports tableau de bord 3CX
              </h2>
             </div>
             <!-- {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }} -->
            </DialogTitle>
            <div class="ml-3 flex h-7 items-center">
             <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              @click="reportList = false">
              <span class="sr-only">Close panel</span>
              <XIcon class="h-6 w-6" aria-hidden="true" />
             </button>
            </div>
           </div>
          </div>
          <div class="relative mt-4 flex-1">
           <!-- Replace with your content -->
           <div class="absolute border-gray-200 bg-white w-full pb-2">
            <div class="flex flex-col mb-0">
             <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div
               class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
               <div
                class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                <div
                 class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                 <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                   <tr>
                    <!-- Render the th element only if header.name is not 'id' -->
                    <template v-for="(header, index) in thead">
                     <th
                      v-if="header.name !== 'id'"
                      :key="index"
                      scope="col"
                      :class="{
                       'pl-4 pr-3 sm:pl-6': index === 1,
                       'px-3': index !== 1,
                      }"
                      class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                      <span
                       v-if="
                        header.name.includes('_') &&
                        !header.name.includes('pattern') &&
                        !header.name === 'start_at'
                       ">
                       {{ $t(header.name.split("_")[1]) }}
                      </span>
                      <span v-else-if="header.name === 'repeat'">
                       Planifié
                      </span>
                      <span v-else-if="header.name === 'repeat_pattern'">
                       Chaque
                      </span>
                      <span v-else>
                       {{ $t(header.name) }}
                      </span>
                     </th>
                    </template>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                     <span class="sr-only">Edit</span>
                    </th>
                   </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white truncate">
                   <slot
                    :row="row"
                    v-for="(row, rowIndex) in tbody"
                    :key="rowIndex">
                    <tr>
                     <!-- Render the td element only if the key is not 'id' -->
                     <template v-for="(value, key, index) in row">
                      <td
                       v-if="key !== 'id' && key !== 'start_at'"
                       :key="key"
                       :class="{
                        'pl-4 pr-3 sm:pl-6 font-medium text-gray-900':
                         index === 1,
                        'px-3 text-gray-500': index !== 1,
                       }"
                       class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
                       <span class="truncate" v-if="value">
                        <span v-if="key === 'start' || key === 'end'">
                         <span v-if="row['repeat'] == 1 && key === 'end'">
                          <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           class="w-5 h-5">
                           <path
                            d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                          </svg>
                         </span>
                         <span
                          v-else-if="row['repeat'] == 1 && key === 'start'">
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                         <span v-else>
                          {{ $d(new Date(value), "shortText", "fr") }}
                         </span>
                        </span>
                        <span v-else-if="key === 'repeat'">
                         <div v-if="value">
                          <span
                           class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                           >Oui</span
                          >
                         </div>
                         <div v-else>
                          <span class="px-2 py-1 bg-blue-300 text-blue-600"
                           >Non</span
                          >
                         </div>
                        </span>
                        <span v-else>
                         {{ $t(value) }}
                        </span>
                       </span>
                       <span v-else>
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                        </svg>
                       </span>
                      </td>
                     </template>
                     <td
                      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center gap-2">
                      <a
                       :href="`https://presentation.cx-engine.net/dashboard/${row.id}`"
                       target="_blank"
                       :class="[
                        'relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold text-gray-700 hover:text-teal-600 ring-1 ring-inset ring-gray-300 hover:bg-teal-100 focus:z-10',
                       ]">
                       <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5">
                        <path
                         d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        <path
                         fill-rule="evenodd"
                         d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                         clip-rule="evenodd" />
                       </svg>
                      </a>
                      <button
                       @click.prevent="
                        deleteItem(
                         row.id,
                         $t('delete'),
                         $t('confirmationMessage'),
                         $t('confirm'),
                         $t('goBack'),
                         $t('successMsg')
                        )
                       "
                       type="button"
                       :class="[
                        'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                       ]">
                       <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5">
                        <path
                         fill-rule="evenodd"
                         d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                         clip-rule="evenodd" />
                       </svg>
                      </button>
                     </td>
                    </tr>
                   </slot>
                  </tbody>
                 </table>
                 <PaginationComponent
                  :current_page="dataReports.current_page"
                  :first_page_url="dataReports.first_page_url"
                  :from="dataReports.from"
                  :last_page="dataReports.last_page"
                  :last_page_url="dataReports.last_page_url"
                  :links="dataReports.links"
                  :next_page_url="dataReports.next_page_url"
                  :per_page="dataReports.per_page"
                  :to="dataReports.to"
                  :total="dataReports.total"
                  @action="$emit('action', $event)"
                  @previousPage="previousPage()"
                  @nextPage="nextPage()" />
                </div>
               </div>
              </div>
             </div>
            </div>
            <!-- <div v-else class="pt-20">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
             </svg>
             <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("queueHasNoData") }}
             </h3>
            </div> -->
           </div>
           <!-- /End replace -->
          </div>
         </div>
        </DialogPanel>
       </TransitionChild>
      </div>
     </div>
    </div>
   </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="alertList">
   <Dialog as="div" class="relative z-40" @close="alertList = false">
    <div class="fixed inset-0" />

    <div class="fixed inset-0 overflow-hidden">
     <div class="absolute inset-0 overflow-hidden">
      <div
       class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
       <TransitionChild
        as="template"
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enter-from="translate-x-full"
        enter-to="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leave-from="translate-x-0"
        leave-to="translate-x-full">
        <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
         <div
          class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
          <div class="px-4 sm:px-6">
           <div class="flex items-start justify-between">
            <DialogTitle class="w-full text-lg font-medium text-gray-900">
             <div
              class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
              <h2 class="uppercase text-lg font-bold">
               Rapports tableau de bord 3CX
              </h2>
             </div>
             <!-- {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }} -->
            </DialogTitle>
            <div class="ml-3 flex h-7 items-center">
             <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              @click="alertList = false">
              <span class="sr-only">Close panel</span>
              <XIcon class="h-6 w-6" aria-hidden="true" />
             </button>
            </div>
           </div>
          </div>
          <div class="relative mt-4 flex-1">
           <!-- Replace with your content -->
           <div class="absolute border-gray-200 bg-white w-full pb-2">
            <div class="flex flex-col mb-0">
             <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div
               class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
               <div
                class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                <div
                 class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                 <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                   <tr>
                    <!-- Render the th element only if header.name is not 'id' -->
                    <template v-for="(header, index) in theadAlert">
                     <th
                      v-if="header.name !== 'id'"
                      :key="index"
                      scope="col"
                      :class="{
                       'pl-4 pr-3 sm:pl-6': index === 1,
                       'px-3': index !== 1,
                      }"
                      class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                      <span
                       v-if="
                        header.name.includes('_') &&
                        !header.name.includes('pattern') &&
                        !header.name === 'start_at'
                       ">
                       {{ $t(header.name.split("_")[1]) }}
                      </span>
                      <span v-else-if="header.name === 'repeat'">
                       Planifié
                      </span>
                      <span v-else-if="header.name === 'repeat_pattern'">
                       Chaque
                      </span>
                      <span v-else>
                       {{ $t(header.name) }}
                      </span>
                     </th>
                    </template>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                     <span class="sr-only">Edit</span>
                    </th>
                   </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white truncate">
                   <slot
                    :row="row"
                    v-for="(row, rowIndex) in tbodyAlert"
                    :key="rowIndex">
                    <tr>
                     <!-- Render the td element only if the key is not 'id' -->
                     <template v-for="(value, key, index) in row">
                      <td
                       v-if="key !== 'id' && key !== 'start_at'"
                       :key="key"
                       :class="{
                        'pl-4 pr-3 sm:pl-6 font-medium text-gray-900':
                         index === 1,
                        'px-3 text-gray-500': index !== 1,
                       }"
                       class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
                       <span class="truncate" v-if="value || key === 'start'">
                        <span v-if="key === 'start' || key === 'end'">
                         <span v-if="row['repeat'] == 1 && key === 'start'">
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                         <span v-else>
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                        </span>
                        <span v-else-if="key === 'repeat'">
                         <div v-if="value">
                          <span
                           class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                           >Oui</span
                          >
                         </div>
                         <div v-else>
                          <span class="px-2 py-1 bg-blue-300 text-blue-600"
                           >Non</span
                          >
                         </div>
                        </span>
                        <span v-else>
                         {{ $t(value) }}
                        </span>
                       </span>
                       <span v-else>
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                        </svg>
                       </span>
                      </td>
                     </template>
                     <td
                      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center gap-2">
                      <button
                       @click.prevent="
                        deleteItemAlert(
                         row.id,
                         $t('delete'),
                         $t('confirmationMessage'),
                         $t('confirm'),
                         $t('goBack'),
                         $t('successMsg')
                        )
                       "
                       type="button"
                       :class="[
                        'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                       ]">
                       <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5">
                        <path
                         fill-rule="evenodd"
                         d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                         clip-rule="evenodd" />
                       </svg>
                      </button>
                     </td>
                    </tr>
                   </slot>
                  </tbody>
                 </table>
                 <PaginationComponent
                  :current_page="dataAlerts.current_page"
                  :first_page_url="dataAlerts.first_page_url"
                  :from="dataAlerts.from"
                  :last_page="dataAlerts.last_page"
                  :last_page_url="dataAlerts.last_page_url"
                  :links="dataAlerts.links"
                  :next_page_url="dataAlerts.next_page_url"
                  :per_page="dataAlerts.per_page"
                  :to="dataAlerts.to"
                  :total="dataAlerts.total"
                  @action="$emit('action', $event)"
                  @previousPage="previousPageAlert()"
                  @nextPage="nextPageAlert()" />
                </div>
               </div>
              </div>
             </div>
            </div>
            <!-- <div v-else class="pt-20">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
             </svg>
             <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("queueHasNoData") }}
             </h3>
            </div> -->
           </div>
           <!-- /End replace -->
          </div>
         </div>
        </DialogPanel>
       </TransitionChild>
      </div>
     </div>
    </div>
   </Dialog>
  </TransitionRoot>
  <!-- <div
   v-if="dataAnswerTime.length && labelAnswerTime.length && dataQueueTable.length > 0 && dataSeriesOrigin.length && dataSeriesOrigin[0] && dataSeriesOrigin[0].data && dataSeriesOriginTop.length && dataSeriesOriginTop[0] && dataSeriesOriginTop[0].data"
   class="lg:flex lg:gap-4 sm:space-y-4 lg:space-y-0"
  >
   <QueueAnswerTimeCard :series="dataAnswerTime" :labels="labelAnswerTime" />
   <OriginsCard :key="dataSeriesOrigin[0]" v-show="dataSeriesOrigin[0].data" :data="dataSeriesOrigin[0].data" @quickPeriod="quickPeriodOrigin = $event" :quickPeriod="quickPeriodOrigin" @selectPeriod="periodSelectorOrigin = $event" />
   <OriginsTopCard :key="dataSeriesOriginTop[0]" v-show="dataSeriesOriginTop[0].data" :data="dataSeriesOriginTop[0].data" @quickPeriod="quickPeriodOriginTop = $event" :quickPeriod="quickPeriodOriginTop" @selectPeriod="periodSelectorOriginTop = $event" />
  </div> -->
 </div>
 <PopupShareReportDashboard
  ref="popupShareReport"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <PopupShareReportDashboard
  ref="popupShareScheduleReport"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <PopupCreateAlert
  ref="popupCreateAlert"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getAlertList() : ''"
  @alertList="alertList = true" />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import NoData from "../../components/NoData.vue";
import PleaseSelectElement from "../../components/PleaseSelectElement.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
import PopupCreateAlert from "../../components/PBX/PopupCreateAlert.vue";
import PopupShareReportDashboard from "../../components/PBX/PopupShareReportDashboard.vue";
import TopUsersCard from "../../components/PBX/Dashboard/TopUsersCard.vue";
import TenSecondsCallsCard from "../../components/PBX/Dashboard/TenSecondsCallsCard.vue";
import TotalCallsCard from "../../components/PBX/Dashboard/TotalCallsCard.vue";
import QueueAnswerTimeCard from "../../components/PBX/Dashboard/QueueAnswerTimeCard.vue";
import TopQueuesCard from "../../components/PBX/Dashboard/TopQueuesCard.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import AnsweredCard from "../../components/PBX/Dashboard/AnsweredCard.vue";
import OriginsCard from "../../components/PBX/Dashboard/OriginsCard.vue";
import OriginsTopCard from "../../components/PBX/Dashboard/OriginsTopCard.vue";
import AvgAnswerTimeCard from "../../components/PBX/Dashboard/AvgAnswerTimeCard.vue";
import LostCallsCard from "../../components/PBX/Dashboard/LostCallsCard.vue";
import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";

const elementTypes = {
 0: "extension",
 1: "world",
 2: "undefined (2)",
 4: "queue",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "external_number_no_success",
 14: "call_flow",
 999: "not provided",
};

const calendarOptions = [
 {
  name: "yesterday",
  value: "yesterday",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: ["urlType","showSideNav"],
 components: {
  AnsweredCard,
  OriginsCard,
  OriginsTopCard,
  TopQueuesCard,
  QueueAnswerTimeCard,
  TotalCallsCard,
  AvgAnswerTimeCard,
  LostCallsCard,
  TenSecondsCallsCard,
  TopUsersCard,
  PopupShareReportDashboard,
  PopupCreateAlert,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  PaginationComponent,
  AskConfirmationDialog,
  PbxDashboardSelector,
  PleaseSelectElement,
  NoData,
 },
 data() {
  return {
   totalCallsInboundPbx: 0,
   totalCallsInternalPbx: 0,
   totalCallsOutboundPbx: 0,
   talkingInboundPbx: 0,
   talkingInternalPbx: 0,
   talkingOutboundPbx: 0,
   totalCallsArrPbx: [0, 0, 0],
   totalCallsArrDurationPbx: [0, 0, 0],
   alertList: false,
   reportList: false,
   customDate: null,
   showDatePickers: false,
   period: "",
   calendarOptions,
   selectedPeriod: "",
   answerType: "hour",
   elementTypes,
   quickPeriodAnswered: "last 30 days",
   quickPeriodOrigin: "last 30 days",
   quickPeriodOriginTop: "last 30 days",
   defaultPeriod: 30,
   periodSelectorAnswered: null,
   periodSelectorOrigin: null,
   periodSelectorOriginTop: null,
   dataColumnChart: [],
   dataColumnChartOutbound: [],
   dataColumnChartHour: [],
   dataColumnChartHourOutbound: [],
   dataSeriesOrigin: [],
   dataSeriesOriginTop: [],
   dataColumnChartTopUsers: [],
   dataColumnChartTopUsersLabels: [],
   dataQueueTable: [],
   dataAnswerTime: [],
   labelAnswerTime: [],
   data: undefined,
   totalCalls: 0,
   totalCallsOutbound: 0,
   lostCalls: 0,
   lostCallsOutbound: 0,
   answered_15: 0,
   talking_10: 0,
   waiting: 0,
   waitingOutbound: 0,
   talkingOutbound: 0,
   talking: 0,
   answering: 0,
   answeringOutbound: 0,
   mostCallsHour: "",
   mostCallsHourOutbound: "",
   bestRateHour: "",
   bestRateHourOutbound: "",
   lowestDisuadedRateHour: "",
   lowestAbandonedRateHour: "",
   lowestRateHour: "",
   lowestRateHourOutbound: "",
   totalCallsArr: [0, 0],
   totalCallsArrDuration: [0, 0],
   startDate: null,
   endDate: null,
   thead: [],
   tbody: [],
   theadAlert: [],
   tbodyAlert: [],
   currentSort: "id",
   currentSortDir: "asc",
   dataReports: undefined,
   dataAlerts: undefined,
   page: 1,
   pageAlerts: 1,
  };
 },
 methods: {
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  async setPeriod(val) {
   this.totalCalls = null;
   this.totalCallsOutbound = null;
   this.lostCalls = null;
   this.lostCallsOutbound = null;
   this.answered_15 = null;
   this.talking_10 = null;
   this.waiting = null;
   this.waitingOutbound = null;
   this.talkingOutbound = null;
   this.talking = null;
   this.answering = null;
   this.answeringOutbound = null;
   this.mostCallsHour = null;
   this.mostCallsHourOutbound = null;
   this.bestRateHour = null;
   this.bestRateHourOutbound = null;
   this.lowestDisuadedRateHour = null;
   this.lowestAbandonedRateHour = null;
   this.lowestRateHour = null;
   this.lowestRateHourOutbound = null;
   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   this.totalCallsInboundPbx = 0;
   this.totalCallsInternalPbx = 0;
   this.totalCallsOutboundPbx = 0;
   this.talkingInboundPbx = 0;
   this.talkingInternalPbx = 0;
   this.talkingOutboundPbx = 0;
   this.totalCallsArrPbx = [0, 0, 0];
   this.totalCallsArrDurationPbx = [0, 0, 0];

   this.dataColumnChart = [];
   this.dataColumnChartOutbound = [];
   this.dataColumnChartHour = [];
   this.dataColumnChartHourOutbound = [];
   this.dataSeriesOrigin = [];
   this.dataSeriesOriginTop = [];
   this.dataColumnChartTopUsers = [];
   this.dataColumnChartTopUsersLabels = [];
   this.dataQueueTable = [];
   this.dataAnswerTime = [];
   this.labelAnswerTime = [];

   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   let period = "";
   if (val === "currentWeek") {
    period = this.getCurrentWeek();
   } else if (val === "yesterday") {
    period = this.getYesterday();
   } else if (val === "day") {
    period = this.getCurrentDay();
   } else if (val === "lastWeek") {
    period = this.getLastWeek();
   } else if (val === "currentMonth") {
    period = this.getCurrentMonth();
   } else if (val === "lastMonth") {
    period = this.getLastMonth();
   } else if (val === "last3Months") {
    period = this.getLast3Months();
   } else if (val === "last6Months") {
    period = this.getLast6Months();
   } else {
    period = this.customDate;
   }
   this.startDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.start)
     : period.firstDay;
   this.endDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.end)
     : period.lastDay;

   if (this.urlType ? this.pbxElements && this.pbxElements.length > 0 : true) {
    this.$emit("setLoading", true);
    this.getDataFromElementForColumn()
     .then(() => this.getPbxAndUsersData())
     .then(() => this.getDataTopQueues())
     .then(() => this.getDataFromElementForColumnOutbound())
     .finally(() => this.$emit("setLoading", false));
   }
  },
  async getQueueData() {
   if (this.hostName) {
    let arrayOfElements = [];
    let stringOfElements = "";
    if (this.pbxElements) {
     this.pbxElements.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     stringOfElements = String(arrayOfElements[0]);

     for (let index = 1; index < arrayOfElements.length; index++) {
      stringOfElements =
       stringOfElements + "," + String(arrayOfElements[index]);
     }
    }

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let start = this.formatDateApiRequest(
     this.getFirstDayOfMonth(year, month + 1)
    );
    let end = this.formatDateApiRequest(date);
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/queue-report?start=${this.startDate ? this.startDate : start}&end=${
     this.endDate ? this.endDate : end
    }&dns=${this.pbxElements ? stringOfElements : "*"}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getPbxData(el, sentPeriod, report, dnType) {
   if (this.hostName) {
    let arrayOfElements = [];
    let stringOfElements = "";
    if (this.pbxElements) {
     this.pbxElements.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     stringOfElements = String(arrayOfElements[0]);

     for (let index = 1; index < arrayOfElements.length; index++) {
      stringOfElements =
       stringOfElements + "," + String(arrayOfElements[index]);
     }
    }

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let start = this.formatDateApiRequest(
     this.getFirstDayOfMonth(year, month + 1)
    );
    let end = this.formatDateApiRequest(date);
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/${report}?start=${this.startDate ? this.startDate : start}&end=${
     this.endDate ? this.endDate : end
    }&dns=${this.pbxElements ? stringOfElements : "*"}&dn_type=${dnType}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getPbxAndUsersData() {
   let data = null;
   let date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth();
   let day = date.getDate();
   let start = this.formatDateApiRequest(
    this.getFirstDayOfMonth(year, month + 1)
   );
   let end = this.formatDateApiRequest(date);
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/standard-dashboard?start=${this.startDate ? this.startDate : start}&end=${
    this.endDate ? this.endDate : end
   }`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    data = await res.data["data"];
    this.setPbxData(data);
    this.setTopUsers(data);
   } catch (error) {
    this.errorHandling(error);
   }
  },
  setTopUsers(data) {
   const users = data?.users;
   let sortedUsers = [];

   if (Object.keys(users).length > 0) {
    sortedUsers = Object.entries(users)
     .map(([id, user]) => {
      const sum_answered =
       user.u_outside_calls_made_answered +
       user.u_internal_calls_made_answered +
       user.u_outside_calls_solicited_answered +
       user.u_internal_calls_solicited_answered;
      const sum_total =
       user.u_outside_calls_made +
       user.u_internal_calls_made +
       user.u_outside_calls_solicited +
       user.u_internal_calls_solicited;
      return { id, sum_total, sum_answered, ...user };
     })
     .sort((a, b) => b.sum_answered - a.sum_answered)
     .map(({ id, ...user }) => ({ id, ...user }));
   }
   if (this.pbxElements?.length > 0) {
    sortedUsers = sortedUsers.filter(user => {
     return this.pbxElements.indexOf(user?.id) >= 0;
    });
   }
   sortedUsers = sortedUsers.slice(0, 10);
   const labels = sortedUsers.map(user => user.name);

   const series = [
    {
     name: "Reçus&sup1;",
     data: sortedUsers.map(user => user.u_outside_calls_solicited_answered),
    },
    {
     name: "Emis&sup2;",
     data: sortedUsers.map(user => user.u_outside_calls_made_answered),
    },
    {
     name: "Internes&sup3;",
     data: sortedUsers.map(
      user =>
       user.u_internal_calls_made_answered +
       user.u_internal_calls_solicited_answered
     ),
    },
    // {
    //  name: "u_internal_calls_solicited_answered",
    //  data: sortedUsers.map(user => user.u_internal_calls_solicited_answered),
    // },
   ];
   this.dataColumnChartTopUsers = series;
   this.dataColumnChartTopUsersLabels = labels;
  },
  setPbxData(data) {
   this.totalCallsInboundPbx = data?.pbx?.c_solicited_calls;
   this.totalCallsInternalPbx = data?.pbx?.c_solicited_internal_calls;
   this.totalCallsOutboundPbx = data?.pbx?.c_solicited_outbound_calls;
   this.talkingInboundPbx = data?.pbx?.c_duration;
   this.talkingInternalPbx = data?.pbx?.c_duration_internal;
   this.talkingOutboundPbx = data?.pbx?.c_duration_outbound;
   this.totalCallsArrPbx = [
    this.totalCallsInboundPbx,
    this.totalCallsOutboundPbx,
    this.totalCallsInternalPbx,
   ];
   this.totalCallsArrDurationPbx = [
    this.talkingInboundPbx,
    this.talkingOutboundPbx,
    this.talkingInternalPbx,
   ];
  },
  async getDataFromElementForColumnOutbound(period) {
   let res = undefined;

   let obj = {};
   let dataObjAns = {};
   let dataObjUn = {};

   res = await this.getPbxData(
    "*",
    period,
    "outbound",
    this.urlType === "queue" ? 4 : 0
   );
   if (res) {
    this.setAnsweredUnansweredOutboundByHours(res);
    this.setOutboundTotalValues(res);

    let keys = [];

    keys = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     obj = {};
     dataObjUn = {};
     dataObjAns = {};
     dataObjAns["name"] = "Répondus";
     dataObjAns["data"] = [];
     dataObjUn["name"] = "Non répondus";
     dataObjUn["data"] = [];
     obj["data"] = [];
     obj["labels"] = [];

     let filledArrayDataAns = new Array(days.length).fill(0);
     let filledArrayDataUns = new Array(days.length).fill(0);

     keys.forEach((element, index) => {
      let splitElement = element.split(":");
      let requestDay = splitElement[0];
      if (splitElement[2] === "*" && splitElement[3] === "calls") {
       if (splitElement[4] === "answered") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataAns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) <=
            parseInt(res["daily*"][element.replace("answered", "calls")])
            ? parseInt(res["daily*"][element])
            : parseInt(res["daily*"][element.replace("answered", "calls")])
          );
         }
        }
        dataObjAns["name"] = "Répondus";
        dataObjAns["data"] = filledArrayDataAns;
       }

       if (splitElement[4] === "calls") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataUns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) -
            (parseInt(
             res["daily*"][element.replace("calls:calls", "calls:answered")]
            )
             ? parseInt(
                res["daily*"][element.replace("calls:calls", "calls:answered")]
               ) <= parseInt(res["daily*"][element])
               ? parseInt(
                  res["daily*"][
                   element.replace("calls:calls", "calls:answered")
                  ]
                 )
               : parseInt(res["daily*"][element])
             : 0)
          );
         }
        }
        dataObjUn["name"] = "Non répondus";
        dataObjUn["data"] = filledArrayDataUns;
       }
      } else {
       // in case there is key but no data for element
      }
     });

     obj["labels"] = days;

     obj["data"].splice(0, 1, dataObjAns);
     obj["data"].splice(1, 1, dataObjUn);

     this.dataColumnChartOutbound[0] = obj;
    } else {
     obj = {};

     obj["labels"] = [];
     obj["data"] = [];

     this.dataColumnChartOutbound[0] = obj;
    }
   }
  },
  setOutboundTotalValues(res) {
   let arr = [];
   let data = res["sum_hourly*"];
   let objCalls = {};
   let keys = Object.keys(data);
   keys.forEach(key => {
    let splitKey = key.split(":");
    let keyType = splitKey[3];
    let keyAction = splitKey[4];
    let keyHourly = splitKey[0].slice(-2);
    if (parseInt(keyHourly) >= 0) {
     if (keyType == "calls") {
      if (keyAction === "calls") {
       if (objCalls[`${"calls_" + keyHourly}`]) {
        objCalls[`${"calls_" + keyHourly}`] =
         parseInt(objCalls[`${"calls_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"calls_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "answered") {
       if (objCalls[`${"answered_" + keyHourly}`]) {
        objCalls[`${"answered_" + keyHourly}`] =
         parseInt(objCalls[`${"answered_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"answered_" + keyHourly}`] = parseInt(data[key]);
       }
      }
     }
     if (keyType == "duration") {
      if (keyAction === "answering") {
       if (objCalls[`${"answering_" + keyHourly}`]) {
        objCalls[`${"answering_" + keyHourly}`] =
         parseInt(objCalls[`${"answering_" + keyHourly}`]) +
         parseInt(data[key]);
       } else {
        objCalls[`${"answering_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "talking") {
       if (objCalls[`${"talking_" + keyHourly}`]) {
        objCalls[`${"talking_" + keyHourly}`] =
         parseInt(objCalls[`${"talking_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"talking_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "waiting") {
       if (objCalls[`${"waiting_" + keyHourly}`]) {
        objCalls[`${"waiting_" + keyHourly}`] =
         parseInt(objCalls[`${"waiting_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"waiting_" + keyHourly}`] = parseInt(data[key]);
       }
      }
     }
    }
   });
   let value = null;
   let rate = null;
   let disuadedRate = 1;
   let abandonedRate = 1;
   let lowestRate = 1;
   let totalCalls = 0;
   let totalAnswered = 0;
   let totalTalking = 0;
   let totalWaiting = 0;
   let totalAnswering = 0;
   Object.keys(objCalls).forEach(key => {
    let time = key.split("_")[1];
    if (key.includes("talking")) {
     totalTalking = totalTalking + parseInt(objCalls[key]);
    }
    if (key.includes("waiting")) {
     totalWaiting = totalWaiting + parseInt(objCalls[key]);
    }
    if (key.includes("answering")) {
     totalAnswering = totalAnswering + parseInt(objCalls[key]);
    }
    if (key.includes("calls")) {
     totalCalls = totalCalls + parseInt(objCalls[key]);
     if (time <= 17 && time >= 9 && parseInt(objCalls[key]) > value) {
      value = parseInt(objCalls[key]);
      this.mostCallsHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${value} appels`;
     }
    }
    if (key.includes("answered")) {
     totalAnswered = totalAnswered + parseInt(objCalls[key]);
     if (
      time <= 17 &&
      time >= 9 &&
      parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) > rate
     ) {
      rate =
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <= 1
        ? parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`])
        : 1;
      this.bestRateHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${(rate * 100).toFixed(0)}%`;
     }
     if (
      time <= 17 &&
      time >= 9 &&
      parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) < lowestRate
     ) {
      lowestRate =
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
      this.lowestRateHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${(100 - lowestRate * 100).toFixed(0)}%`;
     }
    }
   });
   this.totalCallsArr[1] = totalCalls;
   this.totalCallsArrDuration[1] = totalTalking;
   this.totalCallsOutbound = totalCalls;
   this.lostCallsOutbound = totalCalls - totalAnswered;
   this.waitingOutbound = totalWaiting;
   this.talkingOutbound = totalTalking;
   this.answeringOutbound = totalAnswering;
  },
  setAnsweredUnansweredOutboundByHours(res) {
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   let data = res["sum_hourly*"];
   let keys = Object.keys(data);
   let labels = [];
   let filledArrayDataAns = [];
   let filledArrayDataUns = [];
   keys.forEach(element => {
    let key = element.split(":");
    let time = key[0].slice(-2);
    if (parseInt(time) >= 0 && labels.indexOf(time) < 0) {
     labels.push(time);
    }
   });
   let sortedLabels = labels.sort((a, b) => parseInt(a) - parseInt(b));
   sortedLabels.forEach((label, index) => {
    keys.forEach(element => {
     let key = element.split(":");
     let time = key[0].slice(-2);
     let dataType = key[3];
     let action = key[4];
     if (parseInt(time) == parseInt(label)) {
      if (dataType === "calls") {
       if (action === "answered") {
        filledArrayDataAns.splice(index, 0, parseInt(data[element]));
        filledArrayDataUns.splice(
         index,
         0,
         parseInt(data[element.replace("answered", "calls")]) -
          parseInt(data[element]) >=
          0
          ? parseInt(data[element.replace("answered", "calls")]) -
             parseInt(data[element])
          : 0
        );
       }
      }
     }
    });
   });
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = sortedLabels;
   //  let filledArrayDataAns = new Array(labels.length).fill(0);
   //  let filledArrayDataUns = new Array(labels.length).fill(0);
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHourOutbound[0] = obj;
  },
  async getDataFromElementForColumn(period) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   let obj = {};
   let dataObjAns = {};
   let dataObjUn = {};

   res = await this.getPbxData("*", period, "answered", 4);

   if (res) {
    let keys = [];

    keys = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     obj = {};
     dataObjUn = {};
     dataObjAns = {};
     dataObjAns["name"] = "Répondus";
     dataObjAns["data"] = [];
     dataObjUn["name"] = "Non répondus";
     dataObjUn["data"] = [];
     obj["data"] = [];
     obj["labels"] = [];

     let filledArrayDataAns = new Array(days.length).fill(0);
     let filledArrayDataUns = new Array(days.length).fill(0);

     keys.forEach((element, index) => {
      let splitElement = element.split(":");
      let requestDay = splitElement[0];
      if (splitElement[2] === "*") {
       if (splitElement[3] === "answered") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataAns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) <=
            parseInt(res["daily*"][element.replace("answered", "calls")])
            ? parseInt(res["daily*"][element])
            : parseInt(res["daily*"][element.replace("answered", "calls")])
          );
         }
        }
        dataObjAns["name"] = "Répondus";
        dataObjAns["data"] = filledArrayDataAns;
       }

       if (splitElement[3] === "calls") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataUns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) -
            (parseInt(res["daily*"][element.replace("calls", "answered")])
             ? parseInt(res["daily*"][element.replace("calls", "answered")]) <=
               parseInt(res["daily*"][element])
               ? parseInt(res["daily*"][element.replace("calls", "answered")])
               : parseInt(res["daily*"][element])
             : 0)
          );
         }
        }
        dataObjUn["name"] = "Non répondus";
        dataObjUn["data"] = filledArrayDataUns;
       }
      } else {
       // in case there is key but no data for element
      }
     });

     obj["labels"] = days;

     obj["data"].splice(0, 1, dataObjAns);
     obj["data"].splice(1, 1, dataObjUn);

     this.dataColumnChart[0] = obj;
    } else {
     obj = {};

     obj["labels"] = [];
     obj["data"] = [];

     this.dataColumnChart[0] = obj;
    }
   }
   this.totalCalls =
    this.getAnsweredNumb(this.dataColumnChart[0]["data"]) +
    this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
   this.lostCalls = this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
  },
  async getDataTopQueues() {
   let arr = [];
   let res = await this.getQueueData();
   if (res) {
    let dns = [];
    dns = res.dns;
    let keys = Object.keys(res["daily*"]);
    let keysHourly = Object.keys(res["sum_hourly*"]);

    let objCalls = {};

    keysHourly.forEach(keyHour => {
     let splitKeyHour = keyHour.split(":");
     let keyDn = splitKeyHour[4];
     let keyType = splitKeyHour[3];
     let keyHourly = splitKeyHour[0].slice(-2);
     let keyAction = splitKeyHour[5];
     if (keyType == 4 && parseInt(keyHourly) >= 0) {
      if (keyAction === "calls") {
       if (objCalls[`${"calls_" + keyHourly}`]) {
        objCalls[`${"calls_" + keyHourly}`] =
         parseInt(objCalls[`${"calls_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"calls_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "answered") {
       if (objCalls[`${"answered_" + keyHourly}`]) {
        objCalls[`${"answered_" + keyHourly}`] =
         parseInt(objCalls[`${"answered_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"answered_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "disuaded") {
       if (objCalls[`${"disuaded_" + keyHourly}`]) {
        objCalls[`${"disuaded_" + keyHourly}`] =
         parseInt(objCalls[`${"disuaded_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"disuaded_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "abandoned") {
       if (objCalls[`${"abandoned_" + keyHourly}`]) {
        objCalls[`${"abandoned_" + keyHourly}`] =
         parseInt(objCalls[`${"abandoned_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"abandoned_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
     }
    });

    let value = null;
    let rate = 0;
    let disuadedRate = 1;
    let abandonedRate = 1;
    let lowestRate = 1;
    let labels = [];
    Object.keys(objCalls).forEach(key => {
     let time = key.split("_")[1];
     if (key.includes("calls")) {
      labels.push(time);
      if (parseInt(objCalls[key]) > value) {
       value = parseInt(objCalls[key]);
       this.mostCallsHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${value} appels`;
      }
     }
     if (key.includes("answered")) {
      if (
       time <= 17 &&
       time >= 9 &&
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) > rate
      ) {
       rate = parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.bestRateHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${(rate * 100).toFixed(0)}%`;
      }
      if (
       time <= 17 &&
       time >= 9 &&
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
        lowestRate
      ) {
       lowestRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestRateHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${(100 - lowestRate * 100).toFixed(0)}%`;
      }
     }
     if (key.includes("disuaded")) {
      if (
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
       disuadedRate
      ) {
       disuadedRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestDisuadedRateHour =
        parseInt(time) +
        "h" +
        "00" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        "00" +
        ":" +
        ` (${(disuadedRate * 100).toFixed(0)}%)`;
      }
     }
     if (key.includes("abandoned")) {
      if (
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
       abandonedRate
      ) {
       abandonedRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestAbandonedRateHour =
        parseInt(time) +
        "h" +
        "00" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        "00" +
        ` (${(abandonedRate * 100).toFixed(0)}%)`;
      }
     }
    });
    let sortedLabels = labels.sort((a, b) => parseInt(a) - parseInt(b));
    this.setAnsweredUnansweredByHour(sortedLabels, objCalls);

    let calls = 0;
    let answered = 0;
    let disuaded = 0;
    let abandoned = 0;
    let answered_15 = 0;
    let talking_10 = 0;
    let waiting = 0;
    let talking = 0;
    let answering = 0;

    // let answering = 0;
    let obj = {};
    keys.forEach(key => {
     let splitKey = key.split(":");
     let keyType = splitKey[3];

     if (keyType == 4) {
      if (splitKey[6] === "calls") {
       calls = parseInt(calls) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "answered") {
       answered = parseInt(answered) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "disuaded") {
       disuaded = parseInt(disuaded) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "abandoned") {
       abandoned = parseInt(abandoned) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("answering") &&
       splitKey[6].includes("_")
      ) {
       if (
        parseInt(
         splitKey[6].split("_")[2]
          ? splitKey[6].split("_")[2]
          : splitKey[6].split("_")[1]
        ) <= 15
       ) {
        answered_15 = parseInt(answered_15) + parseInt(res["daily*"][key]);
       }
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("talking") &&
       splitKey[6].includes("_")
      ) {
       if (
        parseInt(
         splitKey[6].split("_")[2]
          ? splitKey[6].split("_")[2]
          : splitKey[6].split("_")[1]
        ) <= 10
       ) {
        talking_10 = parseInt(talking_10) + parseInt(res["daily*"][key]);
       }
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("waiting") &&
       !splitKey[6].includes("_")
      ) {
       waiting = parseInt(waiting) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("talking") &&
       !splitKey[6].includes("_")
      ) {
       talking = parseInt(talking) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("answering") &&
       !splitKey[6].includes("_")
      ) {
       answering = parseInt(answering) + parseInt(res["daily*"][key]);
      }
     }
    });

    obj["calls"] = calls;
    obj["answered"] = answered;
    obj["disuaded"] = disuaded;
    obj["abandoned"] = abandoned;
    obj["answered_15"] = answered_15;
    obj["talking_10"] = talking_10;
    obj["waiting"] = waiting;
    obj["talking"] = talking;
    obj["answering"] = answering;
    obj["answered_rate"] = parseInt(((answered / calls) * 100).toFixed(0));
    // obj["answer_time"] = parseInt((answering / calls).toFixed(0));
    arr.push(obj);
    arr = arr.sort((a, b) => {
     return b.calls - a.calls;
    });
    this.dataQueueTable = arr;

    this.answered_15 = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.answered_15);
    }, 0);
    this.talking_10 = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.talking_10);
    }, 0);
    this.waiting = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.waiting);
    }, 0);
    this.talking = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.talking);
    }, 0);
    this.totalCallsArrDuration[0] = this.talking;
    this.answering = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.answering);
    }, 0);
    this.totalCallsArr[0] = this.totalCalls;
   }
  },
  setAnsweredUnansweredByHour(labels, data) {
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = labels;
   let filledArrayDataAns = new Array(labels.length).fill(0);
   let filledArrayDataUns = new Array(labels.length).fill(0);
   labels.forEach((element, index) => {
    if (data[`answered_${element}`]) {
     filledArrayDataAns.splice(index, 1, data[`answered_${element}`]);
    }
    let unanswered =
     data[`calls_${element}`] -
     (data[`answered_${element}`] ? data[`answered_${element}`] : 0);
    filledArrayDataUns.splice(index, 1, unanswered);
   });
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHour[0] = obj;
  },
  async getReportList() {
   let elementType = this.urlType ? (this.urlType === "queue" ? 4 : 0) : "*";
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports?page=${
    this.page
   }&filter[report_type]=dashboard&filter[element_type]=${elementType}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataReports = res.data;

    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "email",
      "email_sender",
     ];
     const excludedValuesHead = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "start_at",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "email",
      "email_sender",
     ];
     this.thead = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbody = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
  },
  async getAlertList() {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/alerts?page=${this.page}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataAlerts = res.data;
    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "repeat",
      "email",
      "stats_type",
      "stats_measurement_type",
      "stats_measurement_limit",
      "stats_measurement_value",
      "end",
      "email_sender",
     ];
     const excludedValuesHead = [
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "start_at",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "repeat",
      "email",
      "stats_type",
      "stats_measurement_type",
      "stats_measurement_limit",
      "stats_measurement_value",
      "end",
      "email_sender",
     ];
     this.theadAlert = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbodyAlert = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
  },
  async deleteItem(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getReportList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  async deleteItemAlert(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/alerts/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getAlertList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  nextPage() {
   if (parseInt(this.page) + 1 <= this.dataReports?.last_page) {
    this.page = this.page + 1;
   }
  },
  previousPage() {
   if (parseInt(this.page) - 1 >= 1) {
    // this.$router.push(`/expert-statistics/reports/${this.urlType}/${this.page - 1}`);
    this.page = this.page - 1;
   }
  },
  nextPageAlert() {
   if (parseInt(this.pageAlert) + 1 <= this.dataAlerts?.last_page) {
    this.pageAlert = this.pageAlert + 1;
   }
  },
  previousPageAlert() {
   if (parseInt(this.pageAlert) - 1 >= 1) {
    // this.$router.push(`/expert-statistics/reports/${this.urlType}/${this.pageAlert - 1}`);
    this.pageAlert = this.pageAlert - 1;
   }
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
 },
 mounted() {
  if (this.pbxElementType !== this.urlType || !this.urlType) {
   this.$store.dispatch("groupSelected", false);
   this.$store.dispatch("groupSelectedName", []);
   this.$store.dispatch("pbxElements", null);
  }

  // this.getDataFromElementForColumn(
  //  this.periodSelectorAnswered
  //   ? this.periodSelectorAnswered
  //   : this.defaultPeriod
  // );
  // this.getDataFromElementOrigins(this.periodSelectorOrigin ? this.periodSelectorOrigin : this.defaultPeriod);
  // this.getDataFromElementOriginsTop(this.periodSelectorOriginTop ? this.periodSelectorOriginTop : this.defaultPeriod);
  // this.getDataTopQueues();
  this.selectedPeriod = "lastMonth";
  this.getReportList();
  this.getAlertList();
 },
 renderTriggered() {},
 created() {},
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),
 },
 watch: {
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
   this.$store.dispatch("groupSelected", false);
   this.$store.dispatch("groupSelectedName", []);
   this.setPeriod(this.selectedPeriod);
   this.getReportList();
   this.getAlertList();
  },
  page: function (val) {
   this.getReportList();
  },
  selectedPeriod: function (val) {
   this.setPeriod(val);
  },
  customDate: function (val) {
   if (this.selectedPeriod === "custom") {
    this.setPeriod("custom");
   } else {
    this.selectedPeriod = "custom";
   }
  },
  periodSelectorAnswered: function (val) {
   //  this.getDataFromElementForColumn(val);
  },
  periodSelectorOrigin: function (val) {
   //  this.getDataFromElementOrigins(val);
  },
  periodSelectorOriginTop: function (val) {
   //  this.getDataFromElementOriginsTop(val);
  },
 },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
 opacity: 0;
}
.beta__logo {
  left: 22rem;
}
</style>
